import React, { Suspense, lazy } from "react";
import { Route, Switch, Router, Redirect } from "react-router-dom";
const Admin = lazy(() => import("@containers/admin"));
const RouteWrapper = lazy(() => import("../../wrappers/route_wrapper"));
const FetchUser = lazy(() => import("./fetch_user"));
const UserModals = lazy(() => import("@src/components/modals/user_modals"));
const AppLayout = lazy(() => import("@layouts/appLayout"));
const ConsultationChimeRoomWrapper = lazy(() =>
  import("./../../wrappers/consultation_chime_room_wrapper")
);
const ModifyAppointment = lazy(() => import("@containers/modify_appointment"));

const NewChatRoom = lazy(() => import("@containers/chat_room"));
const DoctoLayoutStyled = lazy(() => import("@layouts/doctoLayoutStyled"));
const Login = lazy(() => import("@containers/login"));
const NotFound = lazy(() => import("./not_found"));
const AppModals = lazy(() => import("@src/components/modals"));
const Account = lazy(() => import("@containers/account"));
const Messages = lazy(() => import("@containers/messages"));
const PatientAppointments = lazy(() =>
  import("@containers/patient_pages/appointments")
);
const AllAppointments = lazy(() =>
  import("@containers/doctor_pages/appointments/list")
);
const PatientAppointmentsLis = lazy(() =>
  import("@containers/patient_pages/appointments/list")
);
const CompanyAdmin = lazy(() => import("@containers/admin/company-admin"));

import Routes from "@constants/routes";
import { history } from "@modules/";
import { useSelector } from "react-redux";
import LoadingLanding from "./loading_landing";
import { isAuthTokens } from "@helpers/authToken";
import HemisphereLanding from "@containers/company_landings/hemisphere/index";

const HemisphereRoutes = () => {
  const { user, guestUser, fetch } = useSelector((state) => state.session);
  if (fetch || (isAuthTokens(history.location) && !user?.id && !guestUser.id)) {
    return (
      <Suspense fallback={<LoadingLanding />}>
        <FetchUser />
      </Suspense>
    );
  }

  return (
    <Router history={history}>
      <Suspense fallback={<LoadingLanding />}>
        <AppModals />
        {user.id && <UserModals />}
        <Switch>
          <Route exact path="/" component={HemisphereLanding} />
          {/* <Route exact path="/login" component={Login} /> */}
          <RouteWrapper
            path="/account"
            roles={["patient"]}
            layout={AppLayout}
            component={Account}
            theme="hemisphere"
          />
          <Redirect path="/dashboard" to="/account/dashboard" />
          <Redirect path="/hemisphere" to="/" />
          <Route path="/admin" component={Admin} />
          <ConsultationChimeRoomWrapper
            exact
            isPrivate
            path={`/chat-room/:appointmentId`}
            component={NewChatRoom}
          />

          <RouteWrapper
            layout={AppLayout}
            private
            roles={["companyAdmin"]}
            theme="hemisphere"
            path="/company-admin"
            component={CompanyAdmin}
          />
          <RouteWrapper
            isPrivate
            roles={["patient"]}
            layout={AppLayout}
            exact
            path={"/patient/appointments"}
            component={PatientAppointments}
            theme="hemisphere"
          />
          <RouteWrapper
            isPrivate
            roles={["doctor"]}
            layout={AppLayout}
            exact
            path="/appointments/list/:type/:page"
            component={AllAppointments}
            theme="hemisphere"
          />

          <RouteWrapper
            isPrivate
            roles={["patient"]}
            layout={AppLayout}
            exact
            path="/patient/appointments/list/:type/:page"
            component={PatientAppointmentsLis}
            theme="hemisphere"
          />
          <RouteWrapper
            isPrivate
            layout={AppLayout}
            exact
            path="/appointments/:appointmentId/modify"
            component={ModifyAppointment}
            theme="hemisphere"
          />
          <RouteWrapper
            isPrivate
            layout={AppLayout}
            exact
            path={Routes.paths.messages}
            component={Messages}
            theme="hemisphere"
          />
          <RouteWrapper layout={DoctoLayoutStyled} exact component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default HemisphereRoutes;
