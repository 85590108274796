import React from "react";
import LogoutHeader from "./logout_header";
import { Link } from "react-router-dom";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { useSelector } from "react-redux";

function CompanyAdminNav({ showMenu, setShowMenu }) {
  const { width } = useWindowDimensions();
  const user = useSelector((state) => state.session.user);
  const dashboardLink = user.dashboardLink || "/company-admin/discounts";
  const linkDash =
    dashboardLink[0] === "/" ? dashboardLink : `/${dashboardLink}`;

  const hideInMobile = () => {
    if (width < 800) {
      setShowMenu(false);
    }
  };

  return (
    <ul className={`nav ${showMenu ? "d-block" : ""}`}>
      <li>
        <Link to="/company-admin/appointments" onClick={hideInMobile}>
          Appointments
        </Link>
      </li>
      <li>
        <Link to={linkDash} onClick={hideInMobile}>
          Discounts
        </Link>
      </li>
      <LogoutHeader />
    </ul>
  );
}

export default CompanyAdminNav;
