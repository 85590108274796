import React from "react";
import LoginHeader from "./login_header";
import useWindowDimensions from "@hooks/useWindowDimensions";

function Unauthenticated({ setShowMenu, showMenu }) {
  const { width } = useWindowDimensions();
  const hideInMobile = () => {
    if (width < 800) {
      setShowMenu(false);
    }
  };


  return (
    <ul className={`nav ${showMenu ? "d-block" : ""}`}>
      <LoginHeader hideInMobile={hideInMobile} />
    </ul>
  );
}

export default Unauthenticated;
