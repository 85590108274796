import Company from "@helpers/company";
import React, { lazy, Suspense, useEffect, useState } from "react";
const Hemisphere = lazy(() => import("./hemisphere"));
import Loader from "./loader";

const HemisphereWrap = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Hemisphere {...props} />
    </Suspense>
  );
};

export default HemisphereWrap;
