import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap");

  body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  }
  body {
  line-height: 1;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
  display: block;
  }
  ul {
  list-style: none;
  }
  blockquote,
  q {
  quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
  content: "";
  content: none;
  }
  a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  }
  /* change colours to suit your needs */
  ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
  }
  /* change colours to suit your needs */
  mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  }
  del {
  text-decoration: line-through;
  }
  abbr[title],
  dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
  }
  table {
  border-collapse: collapse;
  border-spacing: 0;
  }
  /* change border colour to suit your needs */
  hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
  }
  input,
  select {
  vertical-align: middle;
  }
  a,
  button {
  outline: none;
  border: none;
  font-size: 1em;
  }
  a:hover,
  a:focus,
  button:hover,
  button:focus {
  outline: none;
  }

  html,
  body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  b,
  strong,
  .bold,
  .strong {
  font-family: "Montserrat", sans-serif;
  }

  h1 {
  font-size: 1.875em;
  }

  h2 {
  font-size: 1.625em;
  }

  h3 {
  font-size: 1.375em;
  }

  h4 {
  font-size: 1.125em;
  }

  a {
  cursor: pointer;
  color: #10a6bd;
  text-decoration: none;

  &:hover {
      color: darken(#10a6bd, 5%);
      text-decoration: underline;
  }

  .flex {
    display: flex;
  }
    
  .flex-align-bottom {
    align-items: end;
  }

  .mb-40 {
    margin-bottom: 40px !important;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }  
`;

export default GlobalStyle;
