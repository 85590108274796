import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import App from "./containers/app";
import GlobalStyle from "@src/styled/globalStyles";
// import LinearIcons from "@src/styled/linear-icons";
import "./styles/ui-home-2021.scss";
import "react-tooltip/dist/react-tooltip.css";

const target = document.querySelector("#root");
const root = createRoot(target); // createRoot(container!) if you use TypeScript

if (target.hasChildNodes()) {
  console.log("hydratate");
  hydrateRoot(
    target,
    <Provider store={store}>
      <GlobalStyle />
      {/* <LinearIcons /> */}
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
      </ConnectedRouter>
    </Provider>
  );
} else {
  console.log("render");
  root.render(
    <Provider store={store}>
      <GlobalStyle />
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
      </ConnectedRouter>
    </Provider>
  );
}

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
