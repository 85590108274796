/* eslint-disable react/no-unknown-property */
import React from "react";
import { Modal, ModalInner } from "@layouts/homeLayout/ui/modals";

const ModalWrapper = ({ title, visible, onHideModal, children }) => {
  if (!visible) {
    return null;
  }
  return (
    <Modal id="scroll">
      <ModalInner wide>
        <div className="modal-header">
          <button onClick={onHideModal} className="md-close btn-white"></button>
          {title && (
            <h4 key="h1-nursint" className="jag_title sick-kid-title">
              {title}
            </h4>
          )}
        </div>

        <div className="modal-body">{children}</div>
      </ModalInner>
    </Modal>
  );
};

export default ModalWrapper;
