import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "@hooks/useWindowDimensions";
import LogoutHeader from "./logout_header";

function DefaultNavigation({ setShowMenu, showMenu }) {
  const { width } = useWindowDimensions();
  const domain = window.location.hostname;
  const inHemisphere = domain.includes("hemisphere");

  const hideInMobile = () => {
    if (width < 800) {
      setShowMenu(false);
    }
  };

  return (
    <ul className={`nav ${showMenu ? "d-block" : ""}`}>
      {!inHemisphere && (
        <li>
          <Link to="/account/dashboard" onClick={hideInMobile}>
            DASHBOARD
          </Link>
        </li>
      )}
      <li>
        <Link to="/patient/appointments" onClick={hideInMobile}>
          APPOINTMENT
        </Link>
      </li>
      <li>
        <Link to="/account/profile" onClick={hideInMobile}>
          ACCOUNT
        </Link>
      </li>

      <LogoutHeader />
    </ul>
  );
}

export default DefaultNavigation;
