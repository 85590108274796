import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import session from "./session";
import gui from "./gui";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();
export default combineReducers({
  router: connectRouter(history),
  session,
  gui,
});
