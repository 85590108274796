import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AdvancePaymentModal from "@src/components/modals/advance_payment_modal";
import HemisphereWrap from "@src/components/modals/hemisphere_form/index";
import AppLayout from "@layouts/appLayoutStyled/index";
import { useSelector } from "react-redux";
import UserHelpers from "@helpers/user";
import Company from "@helpers/company";
import Loader from "@src/containers/app/domains/hemisphere.com/loading_landing";

const HemisphereLanding = (props) => {
  const user = useSelector((state) => state.session.user);
  const role = UserHelpers.getRoleName(user);
  if (role === "companyAdmin") {
    return <Redirect path="/" to="/company-admin/appointments" />;
  }
  const [company, setCompany] = useState(null);
  useEffect(() => {
    fetchCompany();
  }, []);
  const fetchCompany = async () => {
    const c = await Company.findBySlug("hemisphere");
    setCompany(c);
  };
  if (!company) {
    return <Loader />;
  }

  return (
    <AppLayout theme="hemisphere">
      <div className="register-scroll">
        <Helmet>
          <title>Docto Online Hospital - Hemisphere</title>
          <meta
            name="description"
            content="Docto Online Hospital, Australia's first - Sign Up Membership"
          />
        </Helmet>

        <div className="register-header company-signup">
          <div className="container">
            <div className="row row-flex">
              <HemisphereWrap company={company} isInline />
            </div>
          </div>
        </div>
        <AdvancePaymentModal />
      </div>
    </AppLayout>
  );
};

export default HemisphereLanding;
