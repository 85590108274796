export const version = "2.5";
export const REFERRAL_COST = 48;
export default { version, REFERRAL_COST };

export const WHEN_TO_SEE_IN_DAYS = [
  { label: "Next available", value: 0 },
  { label: "1 week", value: 4 },
  { label: "2 weeks", value: 7 },
  { label: "3 weeks", value: 11 },
  { label: "4 weeks", value: 15 },
  { label: "5 weeks", value: 20 },
  { label: "6 weeks", value: 24 },
  { label: "7 weeks", value: 28 },
  { label: "8 weeks", value: 32 },
  { label: "9 weeks", value: 36 },
  { label: "10 weeks", value: 40 },
  { label: "1 month", value: 25 },
  { label: "2 months", value: 50 },
  { label: "3 months", value: 72 },
  { label: "4 months", value: 96 },
  { label: "5 months", value: 120 },
  { label: "6 months", value: 144 },
  { label: "7 months", value: 168 },
  { label: "8 months", value: 192 },
  { label: "9 months", value: 216 },
  { label: "10 months", value: 240 },
  { label: "11 months", value: 264 },
  { label: "1 year", value: 329 },
];
export const DURATION = [
  { label: "15 min", value: 15 },
  { label: "30 min", value: 30 },
  { label: "45 min", value: 45 },
  { label: "60 min", value: 60 },
];

export const MINIMUM_DAYS_PRIOR = [
  { label: "No minimum", value: 0 },
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "5 days", value: 5 },
];
