import React, { lazy, Suspense } from "react";

const AdvancePaymentModal = lazy(() => import("./payment_modal"));
import SuspencePaymentModal from "./suspense_payment_modal";
import { useSelector } from "react-redux";

const AdvancePaymentModalWrap = () => {
  const gui = useSelector((state) => state.gui);

  if (!gui.advancePaymentModal.show) { return null }
  return (<Suspense fallback={<SuspencePaymentModal />}>
    <AdvancePaymentModal
      onClose={gui.advancePaymentModal?.onClose}
      onConfirm={gui.advancePaymentModal?.onConfirm}
      onSavePayment={gui.advancePaymentModal?.onSavePayment}
      price={gui.advancePaymentModal?.price}
      details={gui.advancePaymentModal?.details}
      unix={gui.advancePaymentModal?.unix}
      loading={gui.advancePaymentModal?.loading}
      navigation={gui.advancePaymentModal?.navigation}
    />
  </Suspense>)
}

export default AdvancePaymentModalWrap;