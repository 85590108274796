import styled from "styled-components";
import IconCaretDownBlack from "@assets/images/icon-angle-down.svg";

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > label {
    display: block;
    font-size: 10px;
    margin-bottom: 2px;
    color: #767676;
    margin: 5px;
    text-align: left;
  }
  .autocomplete-wrap {
    display: block !important;
    width: 100%;
    position: relative;
    .bot-drop-toggle {
      width: 100% !important;
    }
  }
  .pac-container {
    z-index: 999999999999999999;
    top: initial !important;
    bottom: 100% !important;
    border-radius: 5px;
    left: 0 !important;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  #divAutoComplete {
    position: relative;
  }
  #divAutoComplete .pac-container {
    top: initial !important;
    bottom: 100% !important;
    border-radius: 5px;
    left: 0 !important;
    margin-bottom: 10px;
  }
`;

export const FormError = styled.div`
  display: "inline-block";

  margin: 5px 0px;
  padding: 6px 10px;
  background: rgba(255, 0, 0, 0.15);
  font-size: 0.75em;
  text-align: left;
  color: red;
  transition: border-color;
  border-radius: 5px;
`;

export const FormSuccess = styled.div`
  display: "inline-block";

  margin: 5px 0px;
  padding: 6px 10px;
  background: rgba(19, 156, 48, 0.1);
  border: 1px solid #139c30;
  color: #139c30;
  font-size: 0.75em;
  text-align: left;
  transition: border-color;
  border-radius: 5px;
`;

export const FormWarning = styled.div`
  display: "inline-block";
  margin: 5px 0px;
  padding: 6px 10px;
  background: rgb(250 228 0 / 54%);
  font-size: 0.75em;
  text-align: left;
  color: #c0810c;
  -webkit-transition: border-color;
  transition: border-color;
  border-radius: 5px;
`;

export const InputWrap = styled.div`
  display: flex;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  .before {
    position: absolute;
    line-height: 40px;
    padding: 0px 10px;
    font-size: small;
    color: #767676;
  }
`;

export const FormControl = styled.input`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  padding: 0 10px;
  margin-bottom: ${(props) => (props.clearMargin ? "0" : "15px")};
  background-color: #fff;
  border: 1px solid rgba(22, 23, 56, 0.22);
  transition: border-color;
  border-radius: 5px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  width: 100%;
  padding-left: ${(props) => (props.padded ? "60px" : "10px")};

  &:focus {
    outline: none;
    border: 1px solid rgba(22, 23, 56, 0.35);
  }
`;

export const SelectControl = styled.input`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  margin-bottom: ${(props) => (props.clearMargin ? "0" : "15px")};
  background-color: #fff;
  border: 1px solid rgba(22, 23, 56, 0.22);
  transition: border-color;
  border-radius: 5px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  width: 100%;
  min-height: 42px;
  padding-left: ${(props) => (props.padded ? "55px" : "8px")};
  background-image: url(${IconCaretDownBlack});
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: calc(100% - 10px) 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline-width: 0;

  &:focus {
    outline: none;
    border: 1px solid rgba(22, 23, 56, 0.35);
  }
`;

export const PlainFormControl = styled.input`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  padding: 0 10px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

export const TextAreaFormControl = styled.textarea`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  padding: 0 10px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid rgba(22, 23, 56, 0.22);
  transition: border-color;
  border-radius: 5px;
  font-size: 1em;
  line-height: 40px;
  box-sizing: border-box;
  height: auto;
  line-height: 22px;

  &:focus {
    outline: none;
    border: 1px solid rgba(22, 23, 56, 0.35);
  }
`;
