import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SHOW_POSTCODE_CHECKER, SHOW_GUEST_LINK_MODAL } from "@modules/gui";
import useWindowDimensions from "@hooks/useWindowDimensions";
import LogoutHeader from "./logout_header";

function DefaultNavigation({ showMenu, setShowMenu }) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const hideInMobile = () => {
    if (width < 800) {
      setShowMenu(false);
    }
  };
  const showGuestLink = () => {
    dispatch({
      type: SHOW_GUEST_LINK_MODAL,
      show: true,
    });
    hideInMobile();
  };

  return (
    <ul className={`nav ${showMenu ? "d-block" : ""}`}>
      {/* <li>
        <a href="#" onClick={showPostCodeChecker}>
          POSTCODE CHECKER
        </a>
      </li> */}
      <li>
        <Link to="/appointments" onClick={hideInMobile}>
          Appointments
        </Link>
        <ul className="drop-menu">
          <li>
            <Link to="/appointments/list/upcoming/1" onClick={hideInMobile}>
              Future Appointments
            </Link>
          </li>
          <li>
            <Link to="/appointments/list/past/1" onClick={hideInMobile}>
              Past Appointments
            </Link>
          </li>
          <li>
            <Link to="/appointments/list/cancelled/1" onClick={hideInMobile}>
              Cancelled Appointments
            </Link>
          </li>
          <li>
            <Link to="/appointments/list/pending/1" onClick={hideInMobile}>
              Waiting for patient to accept
            </Link>
          </li>
          <li>
            <Link to="/appointments/list/offers/1" onClick={hideInMobile}>
              Waiting for doctor to accept
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/account/doctor/profile" onClick={hideInMobile}>
          Account
        </Link>
        <ul className="drop-menu">
          <li>
            <Link to="/account/doctor/profile" onClick={hideInMobile}>
              Profile
            </Link>
          </li>
          <li>
            {" "}
            <Link to="/doctor/availability" onClick={hideInMobile}>
              Availability
            </Link>
          </li>
        </ul>
      </li>
      <LogoutHeader />
    </ul>
  );
}

export default DefaultNavigation;
