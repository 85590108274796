import React from "react";
const SuspencePaymentModal = () => {
  return (
    <div className="main-wrap">
      <div className="pm-body">
        <div className="pmf-loading">
          <div className="loader-wrapper">
            <div className="spinner">
              {/* <img src={LoadingImg} className="logo-spin" /> */}
            </div>
            <span>one moment</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuspencePaymentModal;
