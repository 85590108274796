let mainVideoName = "MAIN";

let streamActionsStyle = {
  buttonDisplayMode: "off",
  audioLevelDisplayMode: "off",
  nameDisplayMode: "off",
};

export default {
  apiKey: "45606662",
  statuses: {
    VOID: 0,
    CONNECTING: 1,
    CONNECTED: 2,
    DISCONNECTED: 3,
    REQUESTING_ACCESS: 4,
    DENIED: 5,
    NETWORK_ERROR: 6,
    INIT_PRECALL: 7,
    BANDWITH_TO_LOW: 8,
    UNSUPPORTED_BROWSER: 9,
    DEVICES_NOT_FOUND: 10,
  },
  errors: {
    DENIED: {
      type: "danger",
      title: "Camera and microphone are blocked",
      message:
        "Allow Docto to access your camera and microphone by clicking the camera icon in your URL bar.",
    },
    REQUESTING_ACCESS: {
      type: "warning",
      title: "Request Access",
      message: "Allow Docto to access your camera and microphone",
    },
    DEVICES_NOT_FOUND: {
      type: "danger",
      title: "Unable to get your camera and microphone",
      message:
        "we are not able to get your camera and microfone. Please check your settings and restart your browser",
    },
    UNSUPPORTED_BROWSER: {
      type: "warning",
      title: "Unsupported browser",
      message: "Your browser doesn't support our video calling feature yet.",
    },
    UNSUPPORTED_BROWSER_IOS: {
      type: "warning",
      title: "Unsupported browser",
      message:
        "Your browser doesn't support our video calling feature yet. Please use latest version of Safari",
    },
    NETWORK_ERROR: {
      type: "danger",
      title: "Network error",
      message:
        "Server connection has failed. Please refresh your browser to try again",
    },
    UNSUPPORTED_BROWSER_ANDROID: {
      type: "warning",
      title: "Unsupported browser",
      message:
        "Your browser doesn't support our video calling feature yet. Please use latest version of Google Chrome",
    },
    DOCTO_NETWORK_ERROR: {
      type: "danger",
      title: "Network error",
      message:
        "Connection with DOCTO server has failed. Please refresh your browser to try again",
    },
  },
  ui: {
    mine: "mine",
    cameraTwo: "cameraTwo",
    mainVideo: "mainVideo",
  },
  mainVideoName: mainVideoName,
  mainPublisherId: "publisher",
  otherPublishersId: "subscribers",
  subscribeOptions: {
    id: "mainVideo",
    subscribeToAudio: true,
    subscribeToVideo: true,
    insertMode: "append",
    showControls: false,
    style: { audioBlockedDisplayMode: "auto" },
    height: "100%",
    width: "100%",
  },
  publisherOptions: {
    mainVideo: {
      insertMode: "append",
      usePreviousDeviceSelection: true,
      name: mainVideoName,
      height: "100%",
      width: "100%",
      publishAudio: true,
      publishVideo: true,
      style: streamActionsStyle,
      fitMode: "contain",
    },
    audioCall: {
      insertMode: "append",
      usePreviousDeviceSelection: true,
      name: mainVideoName,
      height: "100%",
      width: "100%",
      publishAudio: true,
      publishVideo: false,
      style: streamActionsStyle,
      fitMode: "contain",
    },
  },
};
