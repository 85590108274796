import queryString from "query-string";
import Token from "@helpers/token";

const getQueryToken = () => {
  const parsed = queryString.parse(location.search);
  const queryToken = parsed?.accessToken;
  return queryToken;
};
const getQueryGuestToken = () => {
  const parsed = queryString.parse(location.search);
  const queryToken = parsed?.guestToken;
  return queryToken;
};
export const isAuthTokens = (location) => {
  const { token, guestToken } = getAuthTokens(location);
  return token || guestToken;
};

export const getAuthTokens = (location) => {
  const queryToken = getQueryToken(location);
  const queryGuestToken = getQueryGuestToken(location);
  const tokenCookies = Token.getToken();
  const tokenGuestCookies = Token.getGuestToken();
  const token = tokenCookies || queryToken;
  const guestToken = queryToken ? null : queryGuestToken || tokenGuestCookies;
  return { token, guestToken };
};
