import React from "react";
import Header from "./header";
import "./../../styles/ui-styled.scss";
import styled from "styled-components";
// import Loader from '@modules/loader';

const AppLayout = ({ children, theme, noHeader, background }) => {
  return (
    <SimpleDiv className={`theme-${theme}`} background={background}>
      {!noHeader && <Header theme={theme} />}
      {children}
    </SimpleDiv>
  );
};

export default AppLayout;
export const SimpleDiv = styled.div`
  background: ${(props) => (props.background ? props.background : "unset")};
`;
