import queryString from "query-string";
import { Cookies } from "react-cookie";
const api = class api {
  static async get(route, options = {}) {
    const getOptions = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }
  static async getBlob(route, options = {}) {
    const getOptions = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: "follow", // manual, *follow, error
      // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    const cookies = new Cookies();
    const parsedUrl = queryString.parseUrl(route);
    const accessToken =
      parsedUrl.query.accessToken ||
      parsedUrl.query.access_token ||
      cookies.get("accessToken") ||
      null;
    opts.headers.Authorization = accessToken;
    return fetch(route, opts).then((r) => r.blob());
  }
  static async post(route, data = {}, options = {}) {
    const getOptions = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }

  static async multiPart(route, data = {}, options = {}) {
    const getOptions = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {},
      body: data,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }

  static async put(route, data = {}, options = {}) {
    const getOptions = {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }

  static async del(route, data = {}, options = {}) {
    const getOptions = {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }
  static async rawRequest(route, options) {
    const handleErrors = async (response) => {
      const text = await response.text();
      let jsonres = {};
      try {
        jsonres = JSON.parse(text);
      } catch (err) {
        jsonres = { text: text };
      }
      if (!response.ok) {
        const errorMessage =
          jsonres?.error?.message || response?.statusText || "unknowError";
        const error = new Error(errorMessage);
        error.response = JSON.stringify(jsonres);
        throw error;
      }
      return jsonres;
    };

    const cookies = new Cookies();
    const parsedUrl = queryString.parseUrl(route);
    const accessToken =
      parsedUrl.query.accessToken ||
      parsedUrl.query.access_token ||
      cookies.get("accessToken") ||
      null;
    options.headers.Authorization = accessToken;
    return fetch(route, options).then(handleErrors);
  }
};

export default api;
