import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoginModal } from "@modules/gui";

const LoginHeader = (props) => {
  const user = useSelector((state) => state.session.user);
  const dispatch = useDispatch();
  const showLogin = () => {
    if (props.hideInMobile) { props.hideInMobile(); }
    dispatch(showLoginModal(true));
  };

  return (
    <li className="logout-link">
      {!user?.id ? (
        <a href="#" onClick={showLogin}>
          <span className="no-wrap">LOG IN</span>
        </a>
      ) : null}
    </li>
  );
};

export default LoginHeader;
