import React from "react";
import ModalWrapper from "@src/components/modal_wrapper/index";
import LoadingSpinner from "@src/components/loader_spinner/index";

const loader = () => {
  return (
    <ModalWrapper title={""} visible={true}>
      <LoadingSpinner />
    </ModalWrapper>
  );
};

export default loader;
