import rootReducer, { history as h } from "./modules";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
export const history = h;
const middleware = [thunkMiddleware, routerMiddleware(history)];
const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));
const store = createStore(rootReducer, composedEnhancers);

export default store;
