import React from "react";
import { Cookies } from "react-cookie";
import { connect, useDispatch } from "react-redux";
import { logout, unmascaradeUser } from "@modules/session";
import Session from "@helpers/session";
import { history } from "@modules/";
const LogOutHeader = ({ user }) => {
  const dispatch = useDispatch();
  const logOut = async () => {
    await Session.logout();
    dispatch(logout());
  };

  const unMask = () => {
    dispatch(unmascaradeUser());
    // history.push("/");
  };

  var cookies = new Cookies();
  var mascaradeToken = cookies.get("mascaradeToken");
  return (
    <li className="logout-link">
      {user.id ? (
        <a href="#" onClick={mascaradeToken ? unMask : logOut}>
          <span className="user-name">{user.firstName}</span>
          <span className="divider">|</span>
          <span className="no-wrap">
            {mascaradeToken ? `UNMASK` : `LOG OUT`}
          </span>
        </a>
      ) : null}
    </li>
  );
};

const mapStateToProps = ({ session }) => ({
  user: session.user,
});
export default connect(mapStateToProps)(LogOutHeader);
